import { FC, useRef, useState } from 'react';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

import { ImageViewerProps } from '@types';
import Spinner from '@components/spinner/Spinner';
import useOnClickOutside from '@hooks/useOutsideClick';

import Icon from '../icon/Icon';
import Modal from '../modal/Modal';
import colors from '../../../themes/colors';

const ImageViewer: FC<ImageViewerProps> = ({
  className,
  src,
  alt,
  width,
  height,
  isExpandable
}) => {
  const ref = useRef(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const handleModalOpen = () => {
    if (!isExpandable) return;
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  useOnClickOutside(ref, () => {
    setIsModalOpen(false);
  });

  return (
    <div className={className}>
      <div
        className="relative overflow-hidden"
        role="presentation"
        onClick={handleModalOpen}
      >
        {/* {isExpandable && (
          <div className="absolute right-0 top-0 cursor-pointer p-2">
            <Icon name="expand" onClick={handleModalOpen} />
          </div>
        )} */}
        <img
          className={`rounded-md border border-gray-300 ${
            !isLoading ? 'block' : 'hidden'
          }`}
          src={src}
          alt={alt}
          width={width}
          height={height}
          onLoad={() => setIsLoading(false)}
        />
        {isLoading && <Spinner />}
      </div>
      <Modal
        isOpen={isModalOpen}
        onCancel={handleModalClose}
        isCloseIconRequired
      >
        <TransformWrapper>
          {({ zoomIn, zoomOut, resetTransform }) => (
            <div
              className="h-[calc(100vh-200px)] rounded-3xl bg-primaryLite p-6 sm:min-w-800 sm:max-w-800"
              ref={ref}
            >
              <div className="mt-8 h-[calc(100%-70px)]">
                <TransformComponent
                  contentStyle={{
                    width: '100%',
                    height: '100%',
                    overflowY: 'auto'
                  }}
                  wrapperStyle={{
                    width: '100%',
                    height: '100%',
                    overflowY: 'auto'
                  }}
                >
                  <img className="w-full" src={src} alt={alt} />
                </TransformComponent>
              </div>
              <div className="flex justify-center">
                <div className="mt-4 flex items-center font-bold text-white ">
                  <div
                    className="rounded-l-full bg-primary px-2 py-1"
                    onClick={() => zoomIn()}
                    role="presentation"
                  >
                    <Icon name="zoom-in" stroke={colors.theme} />
                  </div>
                  <div
                    className="bg-primary px-2 py-1"
                    onClick={() => zoomOut()}
                    role="presentation"
                  >
                    <Icon name="zoom-out" stroke={colors.theme} />
                  </div>
                  <div
                    className="rounded-r-full bg-primary px-2 py-1"
                    onClick={() => resetTransform()}
                    role="presentation"
                  >
                    <Icon name="reset" stroke={colors.theme} />
                  </div>
                </div>
              </div>
            </div>
          )}
        </TransformWrapper>
      </Modal>
    </div>
  );
};

export default ImageViewer;
