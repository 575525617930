import { FC } from 'react';
import clsx from 'clsx';

import { ErrorCardProps } from '@types';
import useMascotTheme from '@hooks/useMascotTheme';
import { getLocalStorageItem } from '@utils/storage';

import Button from '../button/Button';

const ErrorCard: FC<ErrorCardProps> = (props) => {
  const {
    header,
    content,
    handleHomeButtonClick,
    className = '',
    buttonLabel = 'Take me home',
    isButtonDisabled = false
  } = props;

  const mascotTheme = useMascotTheme();
  const errorImage = getLocalStorageItem('errorMascotImgUrl');

  return (
    <div
      className={clsx(
        'rounded-48px mx-4 flex flex-col-reverse items-center justify-center p-6 lg:flex-row lg:p-20 xl:mx-auto xl:min-w-1080',
        className
      )}
    >
      <div className="min-w-full whitespace-break-spaces text-center lg:min-w-520 lg:max-w-520 lg:text-left">
        <h1 className=" text-4xl font-semibold leading-snug text-lightBlack sm:leading-72 lg:text-6xl">
          {header}
        </h1>
        <p className="mt-4 flex justify-center text-justify text-sm text-lightBlack80 lg:justify-start">
          {content}
        </p>
        {!isButtonDisabled && (
          <Button
            label={buttonLabel}
            handleClick={handleHomeButtonClick}
            className="mx-auto mt-8 min-h-14 rounded-full lg:mx-0 lg:min-w-280"
          />
        )}
      </div>
      <img
        src={errorImage ?? mascotTheme?.errorScreen?.mascotImage}
        alt="error"
        className="h-auto w-40  sm:w-230 lg:w-280"
      />
    </div>
  );
};

export default ErrorCard;
